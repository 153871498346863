import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Headers from "../components/Headers";
import Sidebar from "../components/Sidebar";
import { useParams } from "react-router-dom";
import Lottie from "lottie-react";
import { getUploaderSong } from "../features/songs/songSlice";
import { BiTime } from "react-icons/bi";
import AudioPlayer from "../components/AudioPlayer";
import Playlist from "../components/Playlist";
import loadingMusic from "../Lottiefiles/loading_music.json";
import chill from "../Lottiefiles/girlListening.json";
import Helmet from "react-helmet";

const MyUploads = () => {
  const [currentPlaying, setCurrentPlaying] = useState({});
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1);
  const audio = document.getElementById("audio");
  const [isPlaying, setIsPlaying] = useState(false);
  const [searchUploads, setSearchUploads] = useState(null);
  const [songsUploads, setSongsUploads] = useState([]);
  const audioRef = useRef();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { id } = useParams();
  const { songs, isLoading } = useSelector((state) => state.songs);

  useEffect(() => {
    dispatch(getUploaderSong(id));
  }, [dispatch]);

  useEffect(() => {
    setSongsUploads(songs);
  }, [songs]);

  const handleTime = (e) => {
    setCurrentTime(e.target.currentTime);
    setDuration(e.target.duration);
  };

  const whenMusicEnds = (element, index) => {
    if (index === element.length - 1) {
      setCurrentPlaying(element[0]);
    } else {
      setCurrentPlaying(element[index + 1]);
    }
  };

  const searchSongsUploader = () => {
    const searchQuery = songs.filter((song) =>
      song.Title.toLowerCase().includes(searchUploads)
    );

    setSongsUploads(searchQuery);
  };

  const pickSong = (picked) => {
    setCurrentPlaying(picked);
    setIsPlaying(true);
  };

  useEffect(() => {
    if (audio) {
      audio.volume = volume;
    }
  }, [audio, volume]);

  return (
    <div className="flex bg-stone-900">
      <Helmet>
        <title>My Uploads</title>
      </Helmet>
      <Sidebar />
      <div className="flex-[1.5] h-full w-full">
        <Headers
          user={user}
          setSearchUploads={setSearchUploads}
          searchSongsUploader={searchSongsUploader}
        />
        <div className="flex flex-col mt-60 items-center bg-stone-900 <md:mt-0 ">
          <div className="hidden <md:md-indicator">
            <div>
              <label className="font-Roboto text-[18px]">#TITLE</label>
            </div>
            <div className="flex justify-center">
              <label className="font-Roboto text-[18px]">UPLOADED</label>
            </div>
            <div className="flex justify-end">
              <BiTime className="text-white h-6 w-6" />
            </div>
          </div>
          <hr className="hidden <md:md-hr" />
          <div className="absolute h-56 w-[82%] bg-stone-900 top-[65px] items-center justify-center z-[2] text-white flex <md:fixed <md:md-player">
            <Lottie
              animationData={chill}
              loop={true}
              className="h-[200px] w-[200px] <md:md-lottie"
            />
            <label className="font-Kanit text-[30px] animate-bounce-slow <md:md-lettering">
              CHOOSE A MUSIC FROM OUR PLAYLIST AND RELAX.
            </label>
          </div>
          {songsUploads.map((list, index, element) => {
            return (
              <React.Fragment key={list._id}>
                {currentPlaying?._id === list._id && (
                  <>
                    <AudioPlayer
                      audioRef={audioRef}
                      index={index}
                      element={element}
                      setCurrentPlaying={setCurrentPlaying}
                      duration={duration}
                      currentTime={currentTime}
                      audio={audio}
                      setVolume={setVolume}
                      isPlaying={isPlaying}
                      setIsPlaying={setIsPlaying}
                      currentPlaying={currentPlaying}
                    />
                    <audio
                      src={currentPlaying.songLink}
                      ref={audioRef}
                      autoPlay
                      onEnded={() => whenMusicEnds(element, index)}
                      onTimeUpdate={handleTime}
                      id="audio"
                    />
                  </>
                )}
              </React.Fragment>
            );
          })}
          <hr className="border-white text-white border-[0.5px] w-[95%] mt-2 <md:hidden" />
          <div className="h-full w-[90%] overflow-y-auto flex flex-col overflow-auto mt-10 relative <md:h-auto <md:mt-2 <md:mb-2">
            <div className="w-full grid grid-cols-3 grid-flow-row gap-4 p-4 text-white sticky top-0 bg-stone-900 <md:hidden">
              <div>
                <label className="font-Roboto text-[18px]">#TITLE</label>
              </div>
              <div className="flex justify-center">
                <label className="font-Roboto text-[18px]">UPLOADED</label>
              </div>
              <div className="flex justify-end">
                <BiTime className="text-white h-6 w-6" />
              </div>
            </div>
            <hr className="border-green-600 text-white border-[0.5px] w-full mt-2 sticky top-14 <md:hidden" />
            {isLoading ? (
              <div className="flex h-full w-full justify-center items-center">
                <Lottie
                  animationData={loadingMusic}
                  loop={true}
                  style={{ height: "250px", width: "250px" }}
                />
              </div>
            ) : (
              <>
                {songsUploads.map((list) => {
                  return (
                    <Playlist
                      key={list._id}
                      list={list}
                      pickSong={pickSong}
                      isLoading={isLoading}
                    />
                  );
                })}
              </>
            )}
          </div>
          <div className="hidden <md:flex <md:md-barrier"></div>
        </div>
      </div>
    </div>
  );
};

export default MyUploads;
