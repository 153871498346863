import axios from "axios";

const API_URL = "https://shrouded-sands-48617.herokuapp.com/api/songs/";

//Upload song
const uploadSong = async (songData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, songData, config);

  return response.data;
};

//Get Songs
const getSongsMongo = async (searchData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  if (searchData) {
    const response = await axios.get(API_URL + "?id=" + searchData, config);
    return response.data;
  } else {
    const response = await axios.get(API_URL, config);
    return response.data;
  }
};

const getSongUploader = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + id, config);

  return response.data;
};
const songService = { uploadSong, getSongsMongo, getSongUploader };
export default songService;
