import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { register, reset } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../Lottiefiles/loading_Spinner.json";
import Helmet from "react-helmet";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    passwordAgain: "",
  });

  const { name, email, password, passwordAgain } = formData;
  const { user, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isError) {
      alert(message);
    }

    if (isSuccess || user) {
      navigate("/");
    }
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== passwordAgain) {
      alert("PASSWORD DOESN'T MATCH");
    } else {
      const userData = {
        name,
        email,
        password,
      };

      dispatch(register(userData));
    }
  };

  return (
    <div className="flex w-screen h-screen bg-black items-center justify-center">
      <Helmet>
        <title>Register</title>
      </Helmet>
      <img
        src="/imgs/register.jpg"
        alt="register_bg"
        className="w-screen h-screen object-cover opacity-20 pointer-events-none"
      />
      <form
        className="flex h-[550px] w-[800px] bg-black absolute bg-opacity-70 rounded-md"
        onSubmit={handleSubmit}
      >
        <div className="flex-[0.5] flex items-center justify-center bg-black rounded-l-md">
          <img
            src="/imgs/pictureMusic.jpg"
            alt="coverPic"
            className="flex h-full w-full object-cover pointer-events-none opacity-40 rounded-l-md"
          />
          <label className="absolute text-white pointer-events-none font-Righteous mt-60 text-[30px]">
            MUSIC SETS YOU FREE !!
          </label>
        </div>
        <div className="flex flex-col items-center flex-[0.5] justify-center">
          <label className="text-white font-Roboto text-[25px] font-semibold mb-2">
            REGISTER TO LISTEN
          </label>
          <input
            className="border border-blue-400 outline-none mt-7 w-72 placeholder-violet-600"
            placeholder="Name"
            name="name"
            value={name}
            onChange={handleChange}
            required
          />
          <input
            className="border border-blue-400 outline-none mt-7 w-72 placeholder-violet-600"
            placeholder="Email"
            name="email"
            value={email}
            onChange={handleChange}
            required
            type="email"
          />
          <input
            className="border border-blue-400 outline-none mt-7 w-72 placeholder-violet-600"
            placeholder="Password"
            name="password"
            value={password}
            type="password"
            onChange={handleChange}
            required
          />
          <input
            className="border border-blue-400 outline-none mt-7 w-72 placeholder-violet-600"
            placeholder="Confirm Password"
            name="passwordAgain"
            value={passwordAgain}
            type="password"
            onChange={handleChange}
            required
          />
          <button
            className="flex items-center h-10 w-60 justify-center transition-all duration-300 text-white bg-black mt-10 hover:bg-violet-500"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <Lottie
                animationData={loading}
                loop={true}
                style={{ height: "50px", width: "50px" }}
              />
            ) : (
              "Register"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Register;
