import React, { useEffect, useState } from "react";
import { BsFillPlayFill, BsPauseFill, BsVolumeOffFill } from "react-icons/bs";
import { AiFillForward, AiFillBackward } from "react-icons/ai";

const AudioPlayer = ({
  audioRef,
  index,
  element,
  setCurrentPlaying,
  duration,
  currentTime,
  audio,
  setVolume,
  isPlaying,
  setIsPlaying,
  currentPlaying,
}) => {
  const [percent, setPercent] = useState(0);

  const pause = () => {
    setIsPlaying(false);
    const audio = audioRef.current;
    const animate = document.getElementById("vinyl");
    animate.style.animationPlayState = "paused";
    audio.pause();
  };

  const play = () => {
    setIsPlaying(true);
    const audio = audioRef.current;
    const animate = document.getElementById("vinyl");
    animate.style.animationPlayState = "running";
    audio.play();
  };

  useEffect(() => {
    setPercent(Math.round((currentTime / duration) * 100));
    const progress = document.getElementById("progress");
    progress.style.width = `${percent}%`;
  }, [percent, duration, currentTime]);

  const volumeControl = (e) => {
    audio.volume = e.target.value / 100;
    setVolume((audio.volume = e.target.value / 100));
  };

  const nextSong = () => {
    setIsPlaying(true);
    if (index === element.length - 1) {
      setCurrentPlaying(element[0]);
    } else {
      setCurrentPlaying(element[index + 1]);
    }
  };

  const prevSong = () => {
    setIsPlaying(true);
    if (index === 0) {
      let indexPast = element.length - 1;
      setCurrentPlaying(element[indexPast]);
    } else {
      setCurrentPlaying(element[index - 1]);
    }
  };

  const seek = (e) => {
    const width = document.getElementById("progress-container").clientWidth;
    const clickX = e.nativeEvent.offsetX;

    audioRef.current.currentTime = (clickX / width) * duration;
  };

  return (
    <div className="absolute h-56 w-[82%] bg-stone-900 top-[65px] z-[5] overflow-hidden <md:fixed <md:md-player">
      <div className="flex items-center h-full w-full">
        <img
          src="/imgs/vinyl.svg"
          alt="vinyl"
          className="h-40 w-40 animate-spin-slow pointer-events-none ml-6 <md:md-vinyl"
          id="vinyl"
        />
        <div className="h-full w-full flex flex-col justify-center items-center mt-20  <md:mt-5">
          <label className="text-center text-white font-Roboto -mt-6 mb-6 text-[25px] <md:md-letter-title">
            {currentPlaying.Title}
          </label>
          <div
            className="bg-gray-200 rounded-[5px] my-[10px] mx-[0] h-[4px] w-[90%] cursor-pointer <md:md-progress-bar "
            onClick={seek}
            id="progress-container"
          >
            <div
              className={
                "bg-green-700 rounded-[5px] h-full transition-all ease-linear duration-[0.1s]"
              }
              id="progress"
            ></div>
          </div>
          <div className="flex w-full h-0 bg-blue-600 relative left-14 top-6 items-center ">
            <BsVolumeOffFill className="text-green-500 w-7 h-7 <md:hidden" />
            <input
              type="range"
              onChange={volumeControl}
              defaultValue={audio?.volume ? audio?.volume * 100 : 100}
              className="h-[3px] w-[8%] bg-gray-200 rounded-lg accent-green-900 cursor-pointer <md:hidden "
            />
          </div>
          <div className="flex items-center w-[90%] justify-center">
            <button
              className="h-7 w-7 bg-white flex items-center justify-center rounded-full mr-9"
              onClick={prevSong}
            >
              <AiFillBackward />
            </button>
            <button className="h-11 w-11 flex items-center justify-center bg-white rounded-full transition-all duration-100 ">
              {isPlaying ? (
                <BsPauseFill
                  className="h-full w-full text-red-600 active:transition-opacity"
                  onClick={pause}
                />
              ) : (
                <BsFillPlayFill
                  onClick={play}
                  className="h-full w-full text-green-600 active:transition-opacity "
                />
              )}
            </button>

            <button
              className="h-7 w-7 bg-white flex items-center justify-center rounded-full ml-9"
              onClick={nextSong}
            >
              <AiFillForward />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
