import { initializeApp } from "firebase/app";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAuMZHYrTBte2nujKbmFfno-KWqhMCE7A4",
  authDomain: "store-ad4be.firebaseapp.com",
  projectId: "store-ad4be",
  storageBucket: "store-ad4be.appspot.com",
  messagingSenderId: "647199460086",
  appId: "1:647199460086:web:8ecdbbe0e96dde97bafad7",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export async function uploadMusic(file, id) {
  const fileRef = ref(storage, id + ".mp3");

  await uploadBytes(fileRef, file);
  const fileURL = await getDownloadURL(fileRef);

  return fileURL;
}
