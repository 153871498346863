import { BsPlusLg } from "react-icons/bs";
import * as timeago from "timeago.js";
import { useDispatch, useSelector } from "react-redux";
import { deletePlaylist, UpdatePlaylist } from "../features/auth/authSlice";
import { IoCloseSharp } from "react-icons/io5";

const Playlist = ({ list, pickSong, isLoading }) => {
  const dispatch = useDispatch();

  const path = window.location.pathname;
  const addToPlaylist = (object) => {
    dispatch(UpdatePlaylist({ song: object }));
  };

  const removeToPlayList = (object) => {
    dispatch(deletePlaylist({ song: object }));
  };

  return (
    <div
      onClick={() => pickSong(list)}
      className="w-full h-auto grid grid-cols-3 grid-flow-row p-4 text-white cursor-pointer transition-all duration-300 hover:bg-gray-600 rounded-xl"
    >
      <div className="flex items-center">
        {path === "/" ? (
          <button
            onClick={() => addToPlaylist(list._id)}
            title="Add to playlist"
            className="p-2 h-6 w-6 bg-gray-400 flex items-center justify-center mr-3 rounded-full <md:hidden"
          >
            <BsPlusLg className="text-blue-800 h-8 w-8" />
          </button>
        ) : path === "/myplaylist" ? (
          <button
            className="p-2 h-6 w-6 bg-gray-400 flex items-center justify-center mr-3 rounded-full <md:hidden"
            onClick={() => removeToPlayList(list._id)}
          >
            <IoCloseSharp className="text-red-800 h-8 w-8" />
          </button>
        ) : (
          ""
        )}
        <label className="<md:text-[12px]">{list.Title}</label>
      </div>
      <div className="flex justify-center">
        <label className="<md:text-[12px]">
          {timeago.format(list.createdAt)}
        </label>
      </div>
      <div className="flex justify-end ">{list.duration}</div>
    </div>
  );
};

export default Playlist;
