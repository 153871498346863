import { Route, Routes, Navigate } from "react-router-dom";
import Register from "./pages/Register";
import { useSelector } from "react-redux";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import MyPlaylist from "./pages/MyPlaylist";
import MyUploads from "./pages/MyUploads";

function App() {
  const user = useSelector((state) => state.auth.user);

  return (
    <>
      <Routes>
        {!user && (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </>
        )}
        {user && (
          <>
            <Route path="/" element={<Dashboard />} />
            <Route path="/myplaylist" element={<MyPlaylist />} />
            <Route path="/myuploads/:id" element={<MyUploads />} />
          </>
        )}
        <Route path="*" element={<Navigate to={user ? "/" : "/login"} />} />
      </Routes>
    </>
  );
}

export default App;
