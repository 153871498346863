import { HiUser } from "react-icons/hi";
import { FaLock } from "react-icons/fa";
import { ImMusic } from "react-icons/im";
import { IoMdHeadset } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { login, reset } from "../features/auth/authSlice";
import Lottie from "lottie-react";
import loading from "../Lottiefiles/loading_Spinner.json";
import Helmet from "react-helmet";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      alert(message);
    }

    if (isSuccess || user) {
      navigate("/");
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const gotoRegister = () => {
    navigate("/register");
  };

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email,
      password,
    };
    dispatch(login(userData));
  };

  return (
    <div className="flex h-screen w-screen bg-black">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <img
        src="/imgs/login.jpg"
        alt="login_pic"
        className="absolute w-full h-full pointer-events-none opacity-60 object-cover"
      />
      <div className="flex-[0.5] flex items-center justify-center z-40 ">
        <form
          className="flex w-[550px] h-[650px] bg-black bg-opacity-90 flex-col items-center justify-center"
          onSubmit={handleSubmit}
        >
          <label className="font-Righteous text-white absolute mb-[500px] text-[80px] flex items-center">
            LISTEN
            <IoMdHeadset className="text-white ml-3" />
          </label>

          <div className="block mt-20">
            <label className="text-white font-Roboto text-xl flex items-center mb-3">
              <HiUser className="text-white h-5 w-5 mr-1" /> Email Address
            </label>
            <input
              className="outline-none w-[400px] mb-12 block bg-transparent text-white border-b border-b-white"
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={handleChange}
            />
          </div>
          <div className="block">
            <label className="text-white font-Roboto text-xl flex items-center mb-3">
              <FaLock className="text-white h-5 w-5 mr-1" /> Password
            </label>
            <input
              className="outline-none w-[400px] mb-12 block bg-transparent text-white border-b border-b-white"
              placeholder="Password"
              type="password"
              name="password"
              value={password}
              onChange={handleChange}
            />
          </div>
          <div className="flex justify-between w-[70%] mt-12">
            <button
              className="text-white bg-lime-600 w-[160px] h-[40px] transition-all duration-500 rounded-xl hover:bg-green-700 items-center justify-center flex"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <Lottie
                  animationData={loading}
                  loop={true}
                  style={{ height: "50px", width: "50px" }}
                />
              ) : (
                "Login"
              )}
            </button>
            <button
              onClick={gotoRegister}
              className="text-white bg-violet-800 w-[160px] h-[40px] transition-all duration-500 rounded-xl hover:bg-violet-500"
            >
              Register
            </button>
          </div>
        </form>
      </div>
      <div className="flex-[0.5] flex"></div>
    </div>
  );
};

export default Login;
