import { ImSearch } from "react-icons/im";
import { FaCircle } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { useState } from "react";
import ResponsiveSideBar from "./ResponsiveSideBar";

const Headers = ({
  user,
  setSearch,
  searchSomething,
  setMySearch,
  searchPlaylist,
  setSearchUploads,
  searchSongsUploader,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const path = window.location.pathname;

  const searchMethod = (e) => {
    if (path === "/") {
      setSearch(e);
    }
    if (path === "/myplaylist") {
      setMySearch(e);
    }
    if (path.split("/")[1] === "myuploads") {
      setSearchUploads(e);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && path === "/") {
      searchSomething();
    }
    if (e.key === "Enter" && path === "/myplaylist") {
      searchPlaylist();
    }
    if (path.split("/")[1] === "myuploads" && e.key === "Enter") {
      searchSongsUploader();
    }
  };

  return (
    <div className="sticky top-0 flex h-16 w-full bg-black items-center justify-between <md:justify-center z-[999]">
      <div
        className="hidden <md:absolute <md:left-4 <md:flex"
        onClick={() => setShowMenu(!showMenu)}
      >
        <GiHamburgerMenu className="hidden <md:text-white <md:w-5 <md:h-5 <md:flex" />
      </div>
      <ResponsiveSideBar showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="flex w-56 items-center bg-zinc-800 rounded-full ml-6 <md:min-w-0 <md:w-[65%] <md:ml-4">
        <ImSearch className="text-white ml-2" />
        <input
          className="h-7 bg-zinc-800 rounded-full outline-none text-white ml-2 "
          placeholder="Search"
          onChange={(e) => searchMethod(e.target.value)}
          onKeyPress={handleKeyPress}
        />
      </div>

      <div className="flex bg-gray-600 mr-12 p-2 rounded-md text-white <md:hidden">
        <label className="font-Roboto flex items-center">
          Welcome: {user.name}{" "}
          <FaCircle className="ml-2 text-green-500 h-3 w-3" />
        </label>
      </div>
    </div>
  );
};

export default Headers;
