import { useRef, useState } from "react";
import { BsMusicNoteBeamed } from "react-icons/bs";
import { FaArrowLeft, FaCircle } from "react-icons/fa";
import { ImExit } from "react-icons/im";
import { IoMdHeadset } from "react-icons/io";
import { IoLibrarySharp } from "react-icons/io5";
import { SiDiscogs } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { logout } from "../features/auth/authSlice";
import { reset, uploadSong } from "../features/songs/songSlice";
import { motion, AnimatePresence } from "framer-motion";
import { uploadMusic } from "../firebase";
import uniqid from "uniqid";
import Lottie from "lottie-react";
import VinylLoading from "../Lottiefiles/vinyl.json";

const ResponsiveSideBar = ({ showMenu, setShowMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const fileRef = useRef();

  const [music, setMusic] = useState(null);
  const [Audiofile, setAudioFile] = useState(null);
  const [MongoData, setMongoData] = useState({
    duration: 0,
    title: "",
  });
  const [loading, setLoading] = useState(false);

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/");
  };

  const handleChange = (e) => {
    setMusic(e.target.files[0]);
    const urlObj = URL.createObjectURL(e.target.files[0]);
    setAudioFile(urlObj);
  };

  async function songUpload() {
    var minutes = "0" + Math.floor(MongoData?.duration / 60);
    var seconds = "0" + Math.floor(MongoData?.duration - minutes * 60);
    var dur = minutes.substr(-2) + ":" + seconds.substr(-2);

    if (music !== null) {
      setLoading(true);
      const res = await uploadMusic(music, user._id + uniqid());
      dispatch(
        uploadSong({
          text: res,
          title: MongoData.title,
          uploaderName: user.name,
          duration: dur,
        })
      );
      setLoading(false);
      return res;
    } else {
      alert("NO FILE CHOSEN");
    }
  }

  const clickCancel = () => {
    setMusic(null);
    fileRef.current.value = null;
  };
  return (
    <>
      <AnimatePresence>
        {music && (
          <motion.div className="fixed h-full w-full top-0 left-0 bg-black bg-opacity-80 flex items-center justify-center z-[200] ">
            <motion.div
              className="absolute flex h-full w-full justify-center bg-white flex-col items-center z-[201]"
              initial={{
                scale: 0,
              }}
              animate={{
                scale: 1,
              }}
              exit={{ scale: 0 }}
            >
              <div className="flex w-64 h-[390px] flex-col items-center">
                <label className="text-center w-full font-Roboto mt-3 text-[25px] font-semibold">
                  FILE TO BE UPLOAD
                </label>
                <p className="text-center mt-4">FILE NAME</p>
                <label className="text-center w-[95%] mt-1 font-Roboto p-1 bg-slate-400 rounded-md">
                  {music.name}
                </label>
                <p>TITLE</p>
                <input
                  className="text-center w-[95%] mt-1 font-Roboto p-1 bg-slate-400 rounded-md outline-none"
                  onChange={(e) =>
                    setMongoData({ ...MongoData, title: e.target.value })
                  }
                />
                <audio
                  src={Audiofile}
                  controls
                  onLoadedMetadata={(e) =>
                    setMongoData({
                      ...MongoData,
                      duration: e.nativeEvent.srcElement.duration,
                    })
                  }
                />
                <button
                  className="mt-16 w-[95%] h-14 bg-red-600 shadow-lg shadow-gray-800 rounded-lg text-white hover:bg-red-700"
                  onClick={clickCancel}
                >
                  CANCEL
                </button>

                <button
                  className="mt-2 w-[95%] h-14 bg-gray-800 shadow-lg shadow-gray-800 rounded-lg text-white mb-2 hover:bg-gray-700 flex items-center justify-center"
                  disabled={loading}
                  onClick={songUpload}
                >
                  {loading ? (
                    <Lottie
                      animationData={VinylLoading}
                      loop={true}
                      style={{ height: "80px", width: "80px" }}
                    />
                  ) : (
                    "UPLOAD"
                  )}
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <div
        className={`flex bg-neutral-900 w-[250px] h-screen fixed z-[50] top-0 ${
          showMenu ? "left-0" : "-left-[100%]"
        } transition-all duration-500 flex-col justify-center items-center <lg:hidden`}
      >
        <FaArrowLeft
          className="text-white absolute left-[85%] top-[2%] h-7 w-7"
          onClick={() => setShowMenu(!showMenu)}
        />
        <div className="h-full flex w-[95%] flex-col mt-10">
          <label className="font-Righteous text-white text-[30px] items-start flex justify-center w-full ">
            LISTEN <IoMdHeadset className="mt-1" />
          </label>
          <div className="w-full text-white items-center justify-center flex mt-7">
            <label className="w-auto h-full bg-red-700 text-center p-3 rounded-xl flex items-center justify-center">
              Welcome: {user.name}
              <FaCircle className="ml-2 text-green-500 h-3 w-3" />
            </label>
          </div>
          <label
            className="mt-10 items-start flex justify-start text-white font-Roboto p-2 rounded-xl cursor-pointer hover:bg-slate-700"
            htmlFor="resFile"
          >
            <SiDiscogs className="mt-1 h-5 w-5 text-white mr-2" /> Choose a song
            to upload
          </label>

          <input
            hidden
            name="resFile"
            id="resFile"
            type="file"
            onChange={(e) => handleChange(e)}
            accept="audio/mp3,audio/*;capture=microphone"
            ref={fileRef}
          />
          <Link to="/myplaylist">
            <label className="mt-10 items-start flex justify-start text-white font-Roboto p-2 rounded-xl cursor-pointer hover:bg-slate-700">
              <BsMusicNoteBeamed className="mt-1 h-5 w-5 text-white mr-2" /> My
              Playlist
            </label>
          </Link>
          <Link to={`/myuploads/${user._id}`}>
            <label className="mt-10 items-start flex justify-start text-white font-Roboto p-2 rounded-xl cursor-pointer hover:bg-slate-700">
              <IoLibrarySharp className="mt-1 h-5 w-5 text-white mr-2" /> My
              Uploads
            </label>
          </Link>
          <button
            className="mt-10 items-start flex justify-start text-white font-Roboto p-2 rounded-xl cursor-pointer hover:bg-slate-700"
            onClick={onLogout}
          >
            <ImExit className="mt-1 h-5 w-5 text-white mr-2" /> Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default ResponsiveSideBar;
