import axios from "axios";
import { toast } from "react-toastify";

const API_URL = "https://shrouded-sands-48617.herokuapp.com/api/users/";

//REGISTER
const registerUser = async (userData) => {
  const response = await axios.post(API_URL, userData);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

//LOGIN
const loginUser = async (userData) => {
  const response = await axios.post(API_URL + "login", userData);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

//PUT PLAYLIST
const UpdatePlaylist = async (object, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(API_URL + "playlist", object, config);
  toast.success("Successfully Added");
  return response.data;
};

//Remove Item in playlist
const RemovePlaylist = async (object, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(API_URL + "remove", object, config);
  toast.info("Successfully Remove!");
  return response.data;
};

//LOGOUT
const logoutUser = () => {
  localStorage.removeItem("user");
};

//GET ME
const getUser = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + "me", config);

  return response.data;
};

const authService = {
  registerUser,
  logoutUser,
  loginUser,
  UpdatePlaylist,
  getUser,
  RemovePlaylist,
};

export default authService;
