import { useState, useRef } from "react";
import { IoMdHeadset } from "react-icons/io";
import { SiDiscogs } from "react-icons/si";
import { IoLibrarySharp } from "react-icons/io5";
import { BsMusicNoteBeamed } from "react-icons/bs";
import { ImExit } from "react-icons/im";
import { uploadMusic } from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import uniqid from "uniqid";
import { uploadSong } from "../features/songs/songSlice";
import { motion, AnimatePresence } from "framer-motion";
import Lottie from "lottie-react";
import VinylLoading from "../Lottiefiles/vinyl.json";
import { useNavigate } from "react-router-dom";
import { logout, reset } from "../features/auth/authSlice";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [music, setMusic] = useState(null);
  const [loading, setLoading] = useState(false);
  const [MongoData, setMongoData] = useState({
    duration: 0,
    title: "",
  });
  const [Audiofile, setAudioFile] = useState(null);
  const fileRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/");
  };

  async function songUpload() {
    var minutes = "0" + Math.floor(MongoData?.duration / 60);
    var seconds = "0" + Math.floor(MongoData?.duration - minutes * 60);
    var dur = minutes.substr(-2) + ":" + seconds.substr(-2);

    if (music !== null) {
      setLoading(true);
      const res = await uploadMusic(music, user._id + uniqid());
      dispatch(
        uploadSong({
          text: res,
          title: MongoData.title,
          uploaderName: user.name,
          duration: dur,
        })
      );
      setLoading(false);
      return res;
    } else {
      alert("NO FILE CHOSEN");
    }
  }

  const clickCancel = () => {
    setMusic(null);
    fileRef.current.value = null;
  };

  const handleChange = (e) => {
    setMusic(e.target.files[0]);
    const urlObj = URL.createObjectURL(e.target.files[0]);
    setAudioFile(urlObj);
  };

  const gotoPlaylist = () => {
    navigate("/myplaylist");
  };
  return (
    <>
      <AnimatePresence>
        {music && (
          <motion.div className="fixed h-full w-full  top-0 left-0 bg-black bg-opacity-80 flex items-center justify-center z-[98] ">
            <motion.div
              className="absolute flex h-auto w-auto bg-white flex-col items-center z-[99] rounded-lg"
              initial={{
                scale: 0,
              }}
              animate={{
                scale: 1,
              }}
              exit={{ scale: 0 }}
            >
              <div className="flex w-64 h-[390px] flex-col items-center z-[100]">
                <label className="text-center w-full font-Roboto mt-3 text-[25px] font-semibold">
                  FILE TO BE UPLOAD
                </label>
                <p className="text-center mt-4">FILE NAME</p>
                <label className="text-center w-[95%] mt-1 font-Roboto p-1 bg-slate-400 rounded-md">
                  {music.name}
                </label>
                <p>TITLE</p>
                <input
                  className="text-center w-[95%] mt-1 font-Roboto p-1 bg-slate-400 rounded-md outline-none"
                  onChange={(e) =>
                    setMongoData({ ...MongoData, title: e.target.value })
                  }
                />
                <audio
                  src={Audiofile}
                  controls
                  onLoadedMetadata={(e) =>
                    setMongoData({
                      ...MongoData,
                      duration: e.nativeEvent.srcElement.duration,
                    })
                  }
                />
                <button
                  className="mt-16 w-[95%] h-14 bg-red-600 shadow-lg shadow-gray-800 rounded-lg text-white hover:bg-red-700"
                  onClick={clickCancel}
                >
                  CANCEL
                </button>

                <button
                  className="mt-2 w-[95%] h-14 bg-gray-800 shadow-lg shadow-gray-800 rounded-lg text-white mb-2 hover:bg-gray-700 flex items-center justify-center"
                  disabled={loading}
                  onClick={songUpload}
                >
                  {loading ? (
                    <Lottie
                      animationData={VinylLoading}
                      loop={true}
                      style={{ height: "80px", width: "80px" }}
                    />
                  ) : (
                    "UPLOAD"
                  )}
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <div className="flex-[0.3] flex bg-neutral-900 rounded-br-lg rounded-tr-lg h-screen w-full top-0 p-3 sticky <md:hidden">
        <div className="h-full w-full flex p-3 bg-neutral-900 rounded-lg flex-col">
          <label className="font-Righteous text-white text-[30px] items-start flex justify-center w-full ">
            LISTEN <IoMdHeadset className="mt-1" />
          </label>
          <label
            className="mt-10 items-start flex justify-start text-white font-Roboto p-2 rounded-xl cursor-pointer hover:bg-slate-700"
            htmlFor="file"
          >
            <SiDiscogs className="mt-1 h-5 w-5 text-white mr-2" /> Choose a song
            to upload
          </label>
          <input
            hidden
            name="file"
            id="file"
            type="file"
            onChange={(e) => handleChange(e)}
            accept="audio/mp3,audio/*;capture=microphone"
            ref={fileRef}
          />
          <label
            className="mt-10 items-start flex justify-start text-white font-Roboto p-2 rounded-xl cursor-pointer hover:bg-slate-700"
            onClick={gotoPlaylist}
          >
            <BsMusicNoteBeamed className="mt-1 h-5 w-5 text-white mr-2" /> My
            Playlist
          </label>
          <Link to={`/myuploads/${user._id}`}>
            <label className="mt-10 items-start flex justify-start text-white font-Roboto p-2 rounded-xl cursor-pointer hover:bg-slate-700">
              <IoLibrarySharp className="mt-1 h-5 w-5 text-white mr-2" /> My
              Uploads
            </label>
          </Link>
          <button
            className="mt-10 items-start flex justify-start text-white font-Roboto p-2 rounded-xl cursor-pointer hover:bg-slate-700"
            onClick={onLogout}
          >
            <ImExit className="mt-1 h-5 w-5 text-white mr-2" /> Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
