import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";
import { toast } from "react-toastify";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  user: user ? user : null,
  isErrorUser: false,
  isSuccessUser: false,
  isLoadingUser: false,
  messageUser: "",
};

//LOGIN
export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  try {
    return await authService.loginUser(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//REGISTER
export const register = createAsyncThunk(
  "auth/register",
  async (user, thunkAPI) => {
    try {
      return await authService.registerUser(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const UpdatePlaylist = createAsyncThunk(
  "auth/updatePlaylist",
  async (object, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await authService.UpdatePlaylist(object, token);
    } catch (error) {
      const message = error.response && error.response.data;
      error.toString();
      toast.error(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePlaylist = createAsyncThunk(
  "auth/deletePlaylist",
  async (object, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await authService.RemovePlaylist(object, token);
    } catch (error) {
      const message = error.response && error.response.data;
      error.toString();
      toast.error(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMe = createAsyncThunk("auth/getMe", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await authService.getUser(token);
  } catch (error) {
    const message = error.response && error.response.data;
    error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//LOGOUT

export const logout = createAsyncThunk("auth/logout", async () => {
  await authService.logoutUser();
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoadingUser = false;
      state.isSuccessUser = false;
      state.isErrorUser = false;
      state.messageUser = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoadingUser = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoadingUser = false;
        state.isErrorUser = true;
        state.messageUser = action.payload;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
      })
      .addCase(login.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoadingUser = false;
        state.isSuccessUser = true;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoadingUser = false;
        state.isErrorUser = true;
        state.messageUser = action.payload;
        state.user = null;
      })
      .addCase(UpdatePlaylist.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(UpdatePlaylist.fulfilled, (state, action) => {
        state.isLoadingUser = false;
        state.isSuccessUser = true;
        state.user = action.payload;
      })
      .addCase(UpdatePlaylist.rejected, (state, action) => {
        state.isLoadingUser = false;
        state.isErrorUser = true;
        state.isSuccessUser = false;
        state.messageUser = action.payload;
      })
      .addCase(getMe.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.isLoadingUser = false;
        state.isSuccessUser = true;
        state.user = action.payload;
      })
      .addCase(getMe.rejected, (state, action) => {
        state.isLoadingUser = false;
        state.isErrorUser = true;
        state.isSuccessUser = false;
        state.messageUser = action.payload;
        state.user = null;
      })
      .addCase(deletePlaylist.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(deletePlaylist.fulfilled, (state, action) => {
        state.isLoadingUser = false;
        state.isSuccessUser = true;
        state.user = action.payload;
      })
      .addCase(deletePlaylist.rejected, (state, action) => {
        state.isLoadingUser = false;
        state.isErrorUser = true;
        state.isSuccessUser = false;
        state.messageUser = action.payload;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
